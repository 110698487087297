import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import * as styles from './contact.module.scss'
import { tr } from 'js/translations.js'

import Banner from 'components/Banner'
import BgLink from 'components/BgLink'
import Breadcrumb from 'components/Breadcrumb'
import Inview from 'components/Inview'
import Layout from 'components/Layout'
import Seo from 'components/Seo'
// import Social from 'components/Social'

const Page = ({ data }) => {
  const locale = process.env.GATSBY_LOCALE
  const page = data['page'].nodes[0]
  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
    },
  ]
  let officesPrimary = []
  let officesSecondary = []
  page.offices.forEach((office, i) => {
    if (i < 2) {
      officesPrimary.push(office)
    } else {
      officesSecondary.push(office)
    }
  })

  return (
    <Layout>
      <div>
        <Seo page={page} locale={locale} />
        <Banner image={page.banner} locale={locale} />
        <Breadcrumb items={breadcrumb} />

        <div className='wrapper pad-sides'>
          <div className='inner'>
            <div className={styles.offices}>
              <div className={styles.officeRow}>
                {officesPrimary.map((item, i) => (
                  <OfficeItem
                    key={i}
                    item={item}
                    locale={locale}
                    row='primary'
                  />
                ))}
              </div>
              <div className={styles.officeRow}>
                {officesSecondary.map((item, i) => (
                  <OfficeItem
                    key={i}
                    item={item}
                    locale={locale}
                    row='secondary'
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

const OfficeItem = ({ item, row, locale }) => {
  const addressText = renderRichText(item.address)
  return (
    <Inview
      className={`
      ${styles.office}
      fade-in-up stagger-100 
    `}
    >
      <div className={styles.title}>
        {row === 'primary' && <h2>{item.city}</h2>}
        {row === 'secondary' && <h2>{item.city}</h2>}
      </div>
      <div className={styles.inner}>
        {addressText}
        <BgLink
          to={item.directions}
          external={true}
          text={`${tr('DIRECTIONS', locale)} »`}
        />
      </div>
    </Inview>
  )
}

export const pageQuery = graphql`
  query {
    page: allContentfulContactPage {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        offices {
          city
          address {
            raw
          }
          directions
        }
        seo {
          ...SeoFields
        }
      }
    }
  }
`
